import EngineService from '../core/engine-service';

const ENDPOINT = '/spiro_phone';

export function getRecents(params = {}) {
  return EngineService.get(`${ENDPOINT}/phone_call_logs`, params).then((json) => json);
}

export function createPhoneLog(payload) {
  return EngineService.post(`${ENDPOINT}/phone_call_logs`, payload).then((json) => json);
}

export function getVoicemails(params = {}) {
  return EngineService.get(`${ENDPOINT}/voicemails`, params).then((json) => json);
}

export function getVoicemail(id, params = {}) {
  return EngineService.get(`${ENDPOINT}/voicemails/${id}`, params).then((json) => json);
}

export function getMessages(params = {}) {
  return EngineService.get(`${ENDPOINT}/text_message_threads`, params).then((json) => json);
}

export function getMessage(id, params) {
  return EngineService.get(`${ENDPOINT}/text_message_threads/${id}/text_messages`, params).then(
    (json) => json
  );
}

export function getMessageThread(id, params) {
  return EngineService.get(`${ENDPOINT}/text_message_threads/${id}`, params).then((json) => json);
}

export function createMessage(payload) {
  return EngineService.post(`${ENDPOINT}/text_messages`, {
    text_message: payload,
  }).then((json) => json);
}

export function getContactsWithPhoneNumbers(params) {
  return EngineService.get(`${ENDPOINT}/contacts`, {
    ...params,
    q: "phone!~'mobile_phone!~'home_phone!",
  }).then((json) => json);
}

export function getPhoneNotificationsCounters(params = {}) {
  return EngineService.get(`${ENDPOINT}/notification_counters`, params).then((json) => json);
}
