import { Component } from 'react';

import classNames from 'classnames';
import snakeCase from 'lodash/snakeCase';
import PropTypes from 'prop-types';

import { parseMetaFromResponse, parseMetaFromSearchEndpointResponse } from 'helpers/meta';
import Autocomplete from 'lib/Autocomplete';
import { getCustomObjectsForCustomField } from 'services/custom-objects';
import searchService from 'services/search';
import { getSupportDesks } from 'services/support-desks';

class ResourceLookup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      results: [],
      searchInProgress: false,
      value: props.value || '',
      meta: null,
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value && nextProps.value !== '') {
      this.setState({ value: nextProps.value });
    }
  }

  setMetaState = (data, meta) => {
    if (this.props.resource !== 'support_desk')
      return parseMetaFromSearchEndpointResponse(data, meta);

    return parseMetaFromResponse(meta);
  };

  setItemsForDropdown = (data, meta) => {
    let results = [];
    const anyOpportunity = {
      id: -1,
      name: 'Any',
    };

    const filtersOwnerValues = this.props.filtersOwnerValues.map((f) => ({
      id: f.value,
      type: 'User',
      full_name: f.label,
    }));

    if (this.props.importPage) {
      if (data.length === 0) {
        results = [anyOpportunity];
      } else {
        const result = data.filter((item) => item.id !== -1);
        results = [anyOpportunity, ...result];
      }
    } else {
      results = data;
    }

    if (this.props.filtersOwnerValues.length) {
      if (meta.current_page === 1) {
        results = [
          ...filtersOwnerValues.filter((value) =>
            value.full_name.toLowerCase().includes(this.state.value.toLowerCase())
          ),
          ...data,
        ];
      } else {
        results = data;
      }
    }

    this.setState({
      results,
      searchInProgress: false,
      meta: this.setMetaState(data, meta),
    });
  };

  mapCustomObjectsItemsForDropdown = (customObjects) =>
    customObjects.reduce((items, customObject) => {
      const co = { ...customObject, custom: { ...customObject.custom, id: customObject.id } };
      if (co.custom[snakeCase(this.props.pickerKey)]) {
        return [...items, { ...customObject, name: co.custom[snakeCase(this.props.pickerKey)] }];
      }
      return items;
    }, []);

  handleSearch = (term, page = 1, isChecked) => {
    const searchTerm =
      this.props.resource === 'account' ? term.replace(/ *\([^)]*\) */g, '') : term;
    const params = this.props.resource === 'account' ? { all_accounts: 1 } : {};

    const contextParams =
      this.props.resource === 'contact' ||
      this.props.resource === 'custom_objects' ||
      this.props.resource === 'opportunity'
        ? {
            context_resource_type: this.props.contextType,
            context_resource_id: this.props.contextId,
            with_subaccounts: this.props.resource === 'custom_objects' ? null : true,
            constrain: isChecked ? true : null,
          }
        : null;

    if (this.props.resource !== 'custom_objects' && this.props.resource !== 'support_desk') {
      searchService
        .get(searchTerm, {
          only: this.props.resource,
          per_page: 20,
          page,
          context_filter_id: this.props.contextFilterId,
          ...contextParams,
          ...params,
        })
        .then((results) => {
          this.setItemsForDropdown(results.search, results.meta);
        });
    } else if (this.props.resource === 'support_desk') {
      getSupportDesks().then((res) => {
        this.setItemsForDropdown(res.support_desks, res.meta);
      });
    } else {
      const customObjectsearchTerm = term
        ? { q: `${snakeCase(this.props.pickerKey)}:*${term}*` }
        : {};

      const customParams = { ...customObjectsearchTerm, ...contextParams };
      getCustomObjectsForCustomField(this.props.customFieldId, {
        ...customParams,
        context_filter_id: this.props.contextFilterId,
        page,
      }).then((res) => {
        this.setItemsForDropdown(
          this.mapCustomObjectsItemsForDropdown(res.custom_objects),
          res.meta
        );
      });
    }
  };

  search = (term, page, isChecked) => {
    this.setState({ searchInProgress: true });

    this.handleSearch(term, page, isChecked);
  };

  handleChange = (searchTerm, page = 1, isChecked) => {
    this.search(searchTerm, page, isChecked);
    if (!this.props.allowNonExistingValue) {
      this.setState({ value: searchTerm });
      this.props.onChange('');
    } else {
      this.props.onChange(searchTerm);
    }
  };

  handleFocus = (isChecked) => {
    this.search(this.props.value, undefined, isChecked);
    this.props.onFocus(this.props.value);
  };

  // TODO: Refactor to fire only one setState
  handleBlur = (searchTerm) => {
    this.setState({
      results: [],
    });
    this.props.onBlur(searchTerm);
    if (!this.props.allowNonExistingValue && this.props.value !== this.state.value) {
      this.setState({
        value: '',
      });
    }
  };

  handleCheckboxChange = (searchTerm, page = 1, isChecked) => {
    this.search(searchTerm, page, isChecked);
  };

  render() {
    return (
      <Autocomplete
        {...this.props}
        meta={this.state.meta}
        handleSearch={this.handleChange}
        results={this.state.results}
        loading={this.state.searchInProgress}
        value={this.state.value}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        classes={classNames(this.props.className, { 'compact-lookup': this.props.compactLookup })}
        handleCheckboxChange={this.handleCheckboxChange}
      />
    );
  }
}

ResourceLookup.defaultProps = {
  primary: false,
  secondary: false,
  autoFocus: false,
  name: '',
  value: '',
  placeholder: '',
  disabled: false,
  onFocus: () => {},
  onBlur: () => {},
  onChange: () => {},
  allowNonExistingValue: false,
  importPage: false,
  customFieldId: null,
  pickerKey: '',
  compactLookup: true,
  className: '',
  contextType: '',
  contextId: null,
  contextFilterId: null,
  filtersOwnerValues: [],
};

ResourceLookup.propTypes = {
  resource: PropTypes.string.isRequired,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
  autoFocus: PropTypes.bool,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  allowNonExistingValue: PropTypes.bool,
  importPage: PropTypes.bool,
  customFieldId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pickerKey: PropTypes.string,
  compactLookup: PropTypes.bool,
  className: PropTypes.string,
  contextType: PropTypes.string,
  contextId: PropTypes.number,
  filtersOwnerValues: PropTypes.array,
  contextFilterId: PropTypes.number,
};

export default ResourceLookup;
