import { useState } from 'react';

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import Skeleton from '@mui/material/Skeleton';
import PropTypes from 'prop-types';

import styles from './ImageLink.module.scss';

export default function ImageLink({ file, hasText = false }) {
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  const handleVideoLoad = () => {
    setIsLoading(false);
  };

  const handleError = () => {
    setError('Spiro cannot support this file type in messages. To see the attachment, download ');
    setIsLoading(false);
  };

  if (error && !isLoading)
    return (
      <span className={hasText ? styles['error-container'] : styles.error}>
        <ErrorOutlineOutlinedIcon />
        <span>{error}</span>
        <a href={file.url} target="_blank" rel="noopener noreferrer" className={styles.link}>
          here
        </a>
      </span>
    );

  return (
    <a href={file.url} target="_blank" rel="noopener noreferrer" className={styles['image-link']}>
      <Skeleton
        variant="rounded"
        width={230}
        height={file.content_type && file.content_type.startsWith('video/') ? 126 : 230}
        sx={{ mb: '10px' }}
        className={isLoading ? styles.shown : styles.hidden}
      />
      {file.content_type && file.content_type.startsWith('video/') && (
        <video
          controls
          disablePictureInPicture
          className={isLoading ? styles.hidden : styles.video}
          onLoadedData={handleVideoLoad}
          onError={handleError}
        >
          <source src={file.url} type={file.content_type} />
          Your browser does not support the video tag.
        </video>
      )}
      {file.content_type && file.content_type.startsWith('image/') && (
        <img
          src={file.url}
          alt="media url"
          onError={handleError}
          className={isLoading ? styles.hidden : styles.shown}
          onLoad={handleImageLoad}
        />
      )}
    </a>
  );
}

ImageLink.propTypes = {
  file: PropTypes.shape({
    url: PropTypes.string || '',
    content_type: PropTypes.string,
  }),
  hasText: PropTypes.bool,
};
