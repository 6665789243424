import { useEffect, useRef, useState } from 'react';

import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import EmojiEmotionsOutlinedIcon from '@mui/icons-material/EmojiEmotionsOutlined';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import { ClickAwayListener } from '@mui/material';
import Divider from '@mui/material/Divider';
import FormGroup from '@mui/material/FormGroup';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import classNames from 'classnames';
import EmojiPicker from 'emoji-picker-react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import ReactS3Uploader from 'react-s3-uploader';

import { getPhoneTypeFromNumber } from 'helpers/contacts';
import { removePhoneNumberFormatting } from 'helpers/twilio';
import Button from 'lib/Button';
import TextField from 'lib/TextField';
import { getAll as getTemplates } from 'services/sms-templates';
import { error as errorAlert } from 'state/notifications/actions';

import { getPayload, htmlToPlainTextForSms } from './helpers';
import UploadFilesPreview from './UploadFilesPreview';
import useFileUpload from './useFileUpload';
import { forEachError } from '../../../../helpers/errorHelper';
import TextTemplatesModal from '../../../modals/TextTemplatesModal/TextTemplatesModal';
import { createMessage } from '../../../services/spiro-phone';
import { addNewMessage, setDrafts } from '../../CallDialog/MessagesTab/Conversation/state/reducer';
import { selectDrafts } from '../../CallDialog/MessagesTab/Conversation/state/selectors';

import styles from './MessageForm.module.scss';

function MessageForm({ onSuccess, phone, user, contact = null, className = '', maxRows = '1000' }) {
  const dispatch = useDispatch();
  const uploaderRef = useRef(null);
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEmojiModalVisible, setIsEmojiModalVisible] = useState(false);
  const drafts = useSelector(selectDrafts);
  const draftIndex = drafts.findIndex((draft) => draft.id === phone);
  const [defaultSkinTone, setDefaultSkinTone] = useState(
    localStorage.getItem('defaultSkinTone') || 'neutral'
  );

  const [formData, setFormData] = useState({
    body: draftIndex < 0 ? '' : drafts[draftIndex].value,
    contact_id: contact?.id || null,
    phone_type: 'mobile',
    message_type: 'sms',
    phone,
  });
  const {
    files,
    fileUrls,
    uploading,
    progress,
    getSignedUrl,
    onUploadStart,
    onUploadProgress,
    onUploadError,
    onUploadFinish,
    removeFile,
    resetUploader,
  } = useFileUpload(uploaderRef);

  useEffect(() => {
    if (contact)
      getTemplates({ contact_id: contact.id }).then((json) => {
        setTemplates(json.sms_templates);
      });
    setFormData((prevState) => ({
      ...prevState,
      phone_type: contact ? getPhoneTypeFromNumber(phone, contact) : 'mobile',
    }));
  }, [contact]);

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const clearState = () => {
    setIsLoading(false);
    setSelectedTemplate('');
    setFormData({
      body: '',
      contact_id: contact?.id || null,
      phone_type: contact ? getPhoneTypeFromNumber(phone, contact) : 'mobile',
      message_type: 'sms',
      phone,
    });
    dispatch(setDrafts(drafts.filter((draft) => draft.id !== phone)));
    resetUploader();
  };

  const toggleTemplatesModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  const onSendClick = async () => {
    const payload = getPayload(
      {
        ...formData,
        body: formData.body.trim(),
        phone: removePhoneNumberFormatting(formData.phone),
      },
      fileUrls
    );

    setIsLoading(true);

    try {
      const res = await createMessage(payload);
      dispatch(addNewMessage({ user, ...res }));
      clearState();
      onSuccess();
    } catch (error) {
      setIsLoading(false);
      forEachError(error.data, (e) => dispatch(errorAlert(e)));
    }
  };

  const saveDrafts = (draftMessage = formData.body) => {
    if (draftIndex < 0 && draftMessage) {
      dispatch(setDrafts([...drafts, { id: phone, value: draftMessage }]));
    } else if (draftIndex >= 0 && draftMessage) {
      const newDrafts = [...drafts];
      newDrafts[draftIndex] = { id: phone, value: draftMessage };
      dispatch(setDrafts(newDrafts));
    } else if (draftIndex >= 0 && !draftMessage) {
      dispatch(setDrafts(drafts.filter((draft) => draft.id !== phone)));
    }
  };

  const onTemplateSelect = () => {
    setFormData((prevState) => {
      const newBody = prevState.body ? `${prevState.body}\n${selectedTemplate}` : selectedTemplate;
      const plainText = htmlToPlainTextForSms(newBody);
      saveDrafts(plainText);
      return { ...prevState, body: plainText };
    });
    toggleTemplatesModal();
    setSelectedTemplate('');
  };

  const handleOnBlur = () => saveDrafts();

  const onKeyDown = (e) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      onSendClick();
    }
  };

  const onEmojiModalToggle = () => setIsEmojiModalVisible((prev) => !prev);

  const onSkinToneChange = (skinTone) => {
    setDefaultSkinTone(skinTone);
    localStorage.setItem('defaultSkinTone', skinTone);
  };

  return (
    <>
      <Divider className={styles.divider} />
      <div className={classNames(styles.form, className)}>
        <FormGroup>
          <TextField
            name="body"
            value={formData.body}
            disabled={isLoading}
            onChange={onChange}
            placeholder="Type a new message"
            multiline
            onBlur={handleOnBlur}
            onKeyDown={onKeyDown}
            inputProps={{ maxLength: 1600 }}
            maxRows={maxRows}
          />
        </FormGroup>
        <div>
          {files.length > 0 && (
            <UploadFilesPreview
              files={files}
              onRemove={removeFile}
              isUploading={uploading}
              progress={progress}
            />
          )}
        </div>
        <footer className={styles.footer}>
          <div className={styles.row}>
            <div className={styles.row}>
              <label htmlFor="file" className={styles['file-input']}>
                <div
                  className={
                    uploading && progress < 100
                      ? styles['icon-wrapper-disabled']
                      : styles['icon-wrapper']
                  }
                >
                  <Tooltip
                    title="Upload image or video"
                    arrow
                    classes={{ tooltip: styles.tooltip, arrow: styles.arrow }}
                  >
                    <AddPhotoAlternateOutlinedIcon />
                  </Tooltip>
                </div>
                <ReactS3Uploader
                  ref={uploaderRef}
                  id="file"
                  name="file"
                  className={styles.uploader}
                  disabled={isLoading || (uploading && progress < 100)}
                  getSignedUrl={getSignedUrl}
                  preprocess={onUploadStart}
                  onProgress={onUploadProgress}
                  onError={onUploadError}
                  onFinish={onUploadFinish}
                  accept="image/*,video/*"
                  // this is to prevent sending 'x-amz-acl': 'public-read' for security reasons
                  uploadRequestHeaders={{}}
                />
              </label>
              <IconButton
                className={
                  !contact || templates.length <= 0
                    ? styles['icon-wrapper-disabled']
                    : styles['icon-wrapper']
                }
                onClick={toggleTemplatesModal}
                disabled={!contact || templates.length <= 0}
              >
                <Tooltip
                  title="Choose Template"
                  arrow
                  classes={{ tooltip: styles.tooltip, arrow: styles.arrow }}
                >
                  <NoteAddOutlinedIcon fontSize="medium" />
                </Tooltip>
              </IconButton>
              <IconButton className={styles['icon-wrapper']} onClick={onEmojiModalToggle}>
                <Tooltip
                  title="Emojis"
                  arrow
                  classes={{ tooltip: styles.tooltip, arrow: styles.arrow }}
                >
                  <EmojiEmotionsOutlinedIcon fontSize="medium" />
                </Tooltip>
              </IconButton>
            </div>
            <Button
              color="primary"
              variant="contained"
              onClick={onSendClick}
              disabled={isLoading || uploading}
            >
              {isLoading ? 'Sending' : 'Send'}
            </Button>
          </div>
        </footer>
        {isModalVisible && (
          <TextTemplatesModal
            onClose={toggleTemplatesModal}
            templates={templates.map((t) => ({
              label: t.title,
              value: t.html_body ? t.html_body : t.body,
            }))}
            selectedTemplate={selectedTemplate}
            setSelectedTemplate={setSelectedTemplate}
            onSubmit={onTemplateSelect}
          />
        )}
        {isEmojiModalVisible && (
          <ClickAwayListener onClickAway={onEmojiModalToggle}>
            <div>
              <EmojiPicker
                width={330}
                height={419}
                defaultSkinTone={defaultSkinTone}
                style={{ position: 'absolute', bottom: '128px' }}
                onSkinToneChange={onSkinToneChange}
                onEmojiClick={(e) => {
                  onChange({ target: { value: `${formData.body} ${e.emoji}`, name: 'body' } });
                }}
              />
            </div>
          </ClickAwayListener>
        )}
      </div>
    </>
  );
}

MessageForm.propTypes = {
  phone: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  contact: PropTypes.object,
  className: PropTypes.string,
  maxRows: PropTypes.string,
};

export default MessageForm;
