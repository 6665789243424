import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { setResourceDetails } from 'app/assistant/Assistant/state/reducer';
import { hideNotificationsSlider } from 'app/state/app-notifications/actions';

import { getReaction } from '../helpers';

import styles from './NotificationSubject.module.scss';

function NotificationSubject({ link, subject = [], additionalData = null, type }) {
  const dispatch = useDispatch();

  const closeSlider = () => {
    if (additionalData && type === 'assistant_rule_single_record') {
      dispatch(
        setResourceDetails({
          id: additionalData.resource_id,
          resource: additionalData.resource_type,
        })
      );
    }
    dispatch(hideNotificationsSlider());
  };

  return (
    <Link
      to={link}
      className={styles.subject}
      onClick={closeSlider}
      state={{ refreshValue: uuidv4() }}
    >
      {subject.map((item) => {
        if (item.type === 'reaction') {
          return <span key={uuidv4()}>&nbsp;{getReaction(item.content)}</span>;
        }
        if (item.type === 'link') {
          return (
            <span key={uuidv4()} className={styles.link}>
              {item.content}
            </span>
          );
        }
        return <span key={uuidv4()}>{item.content}</span>;
      })}
    </Link>
  );
}

NotificationSubject.propTypes = {
  subject: PropTypes.array,
  link: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  additionalData: PropTypes.shape({
    assistant_rule_id: PropTypes.string,
    is_enabled_on_assistant_tab: PropTypes.bool,
    resource_id: PropTypes.number,
    resource_type: PropTypes.string,
  }),
};

export default NotificationSubject;
