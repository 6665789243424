import DeleteIcon from '@mui/icons-material/HighlightOff';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';

import styles from './UploadFilesPreview.module.scss';

function UploadFilesPreview({ files, onRemove, isUploading, progress }) {
  return (
    <div className={styles.container}>
      {files.map((f, i) => (
        <span
          key={f.file.name}
          className={f.file.type.startsWith('video/') ? styles['video-item'] : styles.item}
        >
          {isUploading && progress < 100 ? (
            <div className={styles['progress-wrapper']}>
              <Box position="relative" display="inline-flex">
                <CircularProgress
                  variant="determinate"
                  value={100}
                  size={25}
                  thickness={6}
                  sx={{
                    color: '#65558F',
                  }}
                />
                <CircularProgress
                  variant="determinate"
                  value={progress}
                  size={25}
                  thickness={6}
                  sx={{
                    color: '#E8DEF8',
                    position: 'absolute',
                    left: 0,
                  }}
                />
              </Box>
            </div>
          ) : (
            <DeleteIcon onClick={() => onRemove(f.file, i)} className={styles.icon} />
          )}

          {f.file.type.startsWith('video/') ? (
            <video className={styles.video}>
              <source src={f.preview} type={f.file.type} />
              Your browser does not support the video tag.
            </video>
          ) : (
            <img src={f.preview} className={styles.image} alt="upload file preview" />
          )}
        </span>
      ))}
    </div>
  );
}

UploadFilesPreview.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      signedUrl: PropTypes.string,
      publicUrl: PropTypes.string,
    })
  ).isRequired,
  onRemove: PropTypes.func.isRequired,
  isUploading: PropTypes.bool.isRequired,
  progress: PropTypes.number.isRequired,
};

export default UploadFilesPreview;
