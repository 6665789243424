import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  notifications: [],
  notificationsRefCurrent: null,
};

export const pushNotificationSlice = createSlice({
  name: 'push-notifications',
  initialState,
  reducers: {
    addNewPushNotification: (state, { payload }) => ({
      ...state,
      notifications: [...state.notifications, payload],
    }),
    removePushNotification: (state, { payload }) => ({
      ...state,
      notifications: state.notifications.filter((notification) => notification.uid !== payload),
    }),
  },
});

export const { addNewPushNotification, removePushNotification } = pushNotificationSlice.actions;
export default pushNotificationSlice.reducer;
