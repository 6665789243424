import { createAsyncThunk } from '@reduxjs/toolkit';

import { getMessage } from 'services/spiro-phone';
import { error } from 'state/notifications/actions';

import { forEachError } from '../../../../../../helpers/errorHelper';

export const fetchSingleMessage = createAsyncThunk(
  'messages-get',
  async (data, { dispatch, getState }) => {
    const { id, params } = data;

    try {
      const user = getState().user;
      const currentPage = getState().messages.meta.currentPage;
      const isCached = currentPage !== null && params?.page === undefined;

      if (isCached) return Promise.resolve();
      const res = await getMessage(id, params);
      return { user, ...res };
    } catch (err) {
      forEachError(err.data, (e) => dispatch(error(e)));
      return Promise.reject(err);
    }
  }
);
