import { useSelector } from 'react-redux';

import { selectTwilioConnection } from 'components/CallDialog/state/selectors';

export default function useCallerInfo() {
  const connection = useSelector(selectTwilioConnection);

  const isConnectionPending = () => connection?.status() === 'pending';

  const isConnectionOpen = () => connection?.status() === 'open';

  return { isConnectionPending, isConnectionOpen };
}
