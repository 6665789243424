const filterKey = (text) =>
  ({
    account: 'Company',
    contact: 'Contact',
    opportunity: 'Opportunity',
    interaction: 'Activity',
    sales_order: 'Sales Order',
    sales_order_item: 'Sales Order Item',
  })[text] || text;

export function parseActionFieldsProperties(data = []) {
  return Object.entries(data).map(([key, value]) => ({
    value: key,
    label: value.label,
    resource: value.resource,
    resource_for: value.resource_for,
    type: value.type,
    relation: value.relation,
    custom_field_id: value.custom_field_id,
    picker_key: value.custom_object_lookup_picker_key,
    options: value.values,
    custom_object_id: value.custom_object_lookup_custom_field_id,
  }));
}

export function parseActionFields(properties) {
  return Object.entries(properties).map(([key, value]) => ({
    [filterKey(key)]: parseActionFieldsProperties(value),
  }));
}

export const flattenActionFields = (fields) =>
  fields
    .map((option) => {
      const values = Object.values(option).flat();

      return values.map((val) => ({ ...val }));
    })
    .flat(1);

export function parseActionFieldsData(value, fields) {
  const field = flattenActionFields(fields).find((f) => f.value === value);

  const payload = {
    resource: field.resource,
    resource_for: field.resource_for,
    custom_field_id: field.custom_field_id,
    relation: field.relation,
    definition: {
      name: field.value,
      type: field.type,
      label: field.label,
      execute_on: field.execute_on || 'add',
    },
  };

  return { field, payload };
}

export function parseActionsFields(actions, fields) {
  return actions.map((action) => {
    const field = flattenActionFields(fields).find((f) => f.value === action.definition?.name);
    return {
      id: action.id,
      field: { ...field },
      payload: {
        ...action,
      },
    };
  });
}

export const mapToLabelValue = (obj) =>
  Object.keys(obj).map((key) => ({
    label: obj[key],
    value: key,
  }));
